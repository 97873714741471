import React, { useEffect } from 'react';
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
// Install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar]);
function CapabilitesHero() {
   useEffect(() => {
      const intervalId = setInterval(() => {
         // Query all elements with the class 'verticle-box'
         const boxes = document.querySelectorAll('.verticle-box');

         // Iterate over each box and randomly add or remove the 'active' class
         boxes.forEach(box => {
            if (Math.random() > 0.5) {
               box.classList.add('active');
            } else {
               box.classList.remove('active');
            }
         });
      }, 2000); // Change class every 2 seconds

      // Cleanup on component unmount
      return () => clearInterval(intervalId);
   }, []);
   return (

      <section className="service-section capabilities-hero ">
         <div className=" container pt-5">
            <div className="row justify-content-center align-items-center justify-content-lg-between ">
               <div className=" col-lg-12 col-xl-12 text-white order-lg-1 mb-5 mb-lg-0 service-top">
                  <span className="badge bg-muted-purple text-white rounded-pill mb-2">
                     New Signals (Next-Generation Technologies)
                  </span>
                  <h1 className="fs-1">
                     Discover & Harness <span className='text-green'>Signals</span>
                  </h1>
                  <p>Our New Signals Services empower your business with the latest in technological advancements, enabling actionable insights, driving innovation, and enhancing performance. Create systems that improve accuracy, decision-making and effectiveness.</p>
                  <a href="/contact/" className='button-link'>Contact Us
                     <div className="arrow_movement">
                        <img src={BtnArrow} alt='' />
                        <img src={BtnArrowbottom} alt='' />
                     </div>
                  </a>
               </div>
            </div>
         </div>


         <div className='capabilities-animation'>
            <div className='capabilities-left'>
               <div className="cap-box">
                  <div className="verticle-box blue-color">Machine Learning</div>
               </div>
               <div className="cap-box">
                  <div className="verticle-box">Generative AI</div>
                  <div className="verticle-box blue-color">Language Processing</div>
               </div>
               <div className="cap-box">
                  <div className="verticle-box blue-color">Computer Vision</div>
                  <div className="verticle-box">Modeling</div>
                  <div className="verticle-box blue-color">AR</div>
               </div>
            </div>
            <div className='capabilities-right'>
               <div className="cap-box">
                  <div className="verticle-box blue-color">AR</div>
               </div>
               <div className="cap-box">
                  <div className="verticle-box blue-color">Extended Reality</div>
                  <div className="verticle-box">Head Track</div>
               </div>
               <div className="cap-box">
                  <div className="verticle-box blue-color">VR</div>
                  <div className="verticle-box">Analytics</div>
                  <div className="verticle-box blue-color">Neural Networks</div>
               </div>
            </div>
         </div>


      </section>
   )

}

export default CapabilitesHero;